import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { grey } from '@mui/material/colors';
import { TrashCount, TrashType } from 'constants/trash';
import TrashColorSquare from 'components/_include/TrashColorSquare';
import { Section } from 'constants/pages';
import { useLocation } from 'react-router-dom';

type AddressErrorsListItemProps = {
    addressKey: string;
    address: string;
    errors?: Partial<TrashCount>;
    errorsCount: number;
    onClick?: (addressKey: string) => void;
    onHover?: (addressKey: string, enter: boolean) => void;
}

function AddressErrorsListItem({addressKey, address, errors, errorsCount, onClick, onHover }: AddressErrorsListItemProps) {

    const { t } = useTranslation([Namespace.GLOSSARY]);

    const location = useLocation();

    const [name, vicinity] = address.split(",");

    return (
        <ListItemButton
            divider
            onClick={() => onClick ? onClick(addressKey) : {}}
            onMouseEnter={() => onHover ? onHover(addressKey, true) : {}}
            onMouseLeave={() => onHover ? onHover(addressKey, false) : {}}
        >
            <ListItemText
                primary={name}
                secondary={vicinity}
                primaryTypographyProps={{
                    fontSize: ".85rem",
                }}
                secondaryTypographyProps={{
                    fontSize: ".75rem"
                }}
            />
            {location.pathname === `/${Section.STATS}` && errors !== undefined && (
                <Box>
                    {Object.entries(errors).map(([trashType, count]) => (
                        count > 0 && (
                            <Typography
                                key={trashType}
                                variant="body2"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <TrashColorSquare
                                    key={`${trashType}`}
                                    trashType={trashType as TrashType}
                                />
                                <span style={{ marginLeft: ".5rem" }}>
                                    {`${t(trashType, { ns: Namespace.WASTES })}: ${count}`}
                                </span>
                            </Typography>
                        )
                    ))}
                </Box>
            )}

            {/* <ListItemIcon> */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <Box
                    textAlign="center"
                    px={2}
                    >
                    <Typography fontSize="1.2rem" fontWeight="600">{errorsCount}</Typography>
                    <Typography fontSize=".8rem">{t("error", { ns: Namespace.GLOSSARY, count: errorsCount })}</Typography>
                </Box>
                <ChevronRightIcon sx={{ color: grey[400] }} />
            </Box>
            {/* </ListItemIcon> */}
        </ListItemButton >
    );
}

export default AddressErrorsListItem;