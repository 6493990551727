import { ResponsivePie } from "@nivo/pie";
import SectionLoader from 'components/_include/SectionLoader';
import { useMemo } from 'react';
import { Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { TrashType } from 'constants/trash';
import { mergeTrashCounts } from 'helpers/trash';
import { WASTES_COLORS } from "constants/trash";
import ChartTitle from './ChartTitle';
import { useAppSelector, useSortingRules } from "store/hooks";
import { PIE_CHART_THEME } from "constants/theme";
import TrashTypeTooltip from "./TrashTypeTooltip";
import { sum } from "lodash";

type WasteRepartitionPieChartProps = {
    height: number;
}

export type WasteSlice = {
    id: string;
    label: string;
    value: number;
    color: string;
}

function WasteRepartitionPieChart({ height }: WasteRepartitionPieChartProps) {
    const { mergingMapping } = useSortingRules();
    
    const wastesSurfaces = useAppSelector(state => state.stats.data?.wastesSurfaces);
    const loading = useAppSelector(state => state.trucks.list.loading
        || state.areas.list.loading || state.stats.loading || state.collections.list.loading);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);

    const data = useMemo(() => {
        let wastesList: WasteSlice[] = [];
        if (!wastesSurfaces) return wastesList; // results not loaded yet

        let wastes = mergeTrashCounts(mergingMapping, wastesSurfaces);
        let totalErrors = sum(Object.values(wastes)); // total surface of sorting errors

        for (let [wasteType, count] of Object.entries(wastes)) {
            const percentage = Math.round(count / totalErrors * 100);
            if (percentage > 0) {
                wastesList.push({
                    id: wasteType,
                    label: t(wasteType, { ns: Namespace.WASTES }),
                    value: percentage,
                    color: WASTES_COLORS[wasteType as TrashType],
                });
            }
        }

        return wastesList;
    }, [t, wastesSurfaces]);

    return (
        <Box
            justifyContent="center"
        >
            <ChartTitle
                sx={{
                    px: 2,
                }}
            >
                {t("truck_content.title", { ns: Namespace.CHARTS })}
            </ChartTitle>
            <Box
                position="relative"
                width="100%"
                height={theme => theme.spacing(height)}
                py={{ lg: 2, xl: 0 }}
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    <ResponsivePie
                        data={data}
                        theme={PIE_CHART_THEME}
                        valueFormat={(value) => `${value}%`}
                        margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                        padAngle={2}
                        innerRadius={0.4}
                        cornerRadius={4}
                        borderWidth={1}
                        borderColor={{
                            from: "color",
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        colors={{ datum: "data.color" }}
                        arcLabelsTextColor={(d) => d.value > 5 ? "#ffffff" : "rgba(0,0,0,0.8)"}
                        arcLinkLabelsStraightLength={8}
                        arcLinkLabel={d => d.id === TrashType.OTHER ? t('other_small', { ns: Namespace.WASTES }) : `${d.label}`}
                        startAngle={-20}
                        tooltip={({ datum }) => (
                            <TrashTypeTooltip 
                                trashType={datum.id as TrashType} 
                                label={datum.data.label} 
                                value={datum.formattedValue}                                
                                />
                        )}
                    />
                )}
            </Box>
        </Box>
    );
}

export default WasteRepartitionPieChart;