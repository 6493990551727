import { MissionsAddressWithBatchCount } from "constants/types";
import { Timestamp } from "firebase/firestore";
import { UserData } from "./User";

export enum MissionStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}

export type MissionsData = {
    ID: string;
    name: string;
    deadline: string | undefined;
    createdAt?: string;
    addresses?: MissionsAddressWithBatchCount[];
    assigned?: UserData[];
    mapImageURL?: string;
    status?: MissionStatus;
}

export type MissionDbData = {
    ID: string;
    name: string;
    deadline: Timestamp | undefined;
    createdAt?: Timestamp;
    addresses: MissionsAddressWithBatchCount[];
    assigned: UserData[];
    mapImageURL?: string;
    status?: MissionStatus;
}
