import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { Alert, AlertTitle, Container } from "@mui/material";
import FullScreenBatchImage from "components/_include/FullScreenBatchImage";
import SectionLoader from "components/_include/SectionLoader";
import BatchesController from "controllers/batches";
import { getPictureURL, StorageType } from "helpers/storage";
import { Namespace } from "locales/translations";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

/** 
 * Page to visualize a single batch image with its AI results. 
 * Includes buttons to download annotated image.
 */
function SingleImagePage() {
    const { t } = useTranslation([Namespace.SNACKS]);

    const [searchParams] = useSearchParams();

    const partnerID = searchParams.get("partnerID") ?? "";
    const batchID = searchParams.get("imageID") ?? "";

    const loading = useAppSelector(state => state.batches.selected.loading);
    const error = useAppSelector(state => state.batches.selected.error);

    const batchDate = useAppSelector(state => state.batches.selected.data?.timestamp);
    const batchBboxes = useAppSelector(state => state.batches.selected.data?.bboxes ?? []);

    const [imageURL, setImageURL] = useState<string | null>(null);

    const dispatch = useAppDispatch();

    /** Retrieve batch from the partner and batch IDs. */
    useEffect(() => {
        dispatch(BatchesController.getByID(partnerID, batchID, false)) // will trigger error if parameter missing
            .then(batch => {
                if (!batch?.imageURL) { // batch not found: will display invalid link error
                    setImageURL("");
                    return;
                }

                getPictureURL(batch.imageURL, StorageType.BATCHES) // get signed URL
                    .then(pictureURL => setImageURL(pictureURL));
            });
    }, [partnerID, batchID]);

    /** */

    return (
        <Container
            maxWidth="xl"
        >
            {loading || imageURL === null ? (
                <SectionLoader />
            ) : (
                <>
                    {imageURL && (
                        <FullScreenBatchImage 
                            batchID={batchID}
                            loading={loading}
                            pictureDate={moment(batchDate)} 
                            pictureURL={imageURL}
                            bboxes={batchBboxes}                    
                            />
                    )}

                    {(error || imageURL === "") && (
                        <Alert
                            variant="filled"
                            severity="error"
                            icon={<DangerousRoundedIcon fontSize="inherit" />}
                        >
                            <AlertTitle>
                                {t("invalid_link", { ns: Namespace.SNACKS })}
                            </AlertTitle>
                            {t("not_found", { ns: Namespace.SNACKS })}
                        </Alert>
                    )}
                </>
            )}
        </Container>
    );
}

export default SingleImagePage;