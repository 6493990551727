import { DEFAULT_MERGING_WASTE_MAPPING, DEFAULT_SORTING_ERRORS, MergingWasteMapping, SortingRules, TrashCount, TrashType, } from "constants/trash";
import { omit } from "lodash";
import { BatchResults } from "models/Batch";
import { CollectionType } from "models/Collection";
import Partner from "models/Partner";
import { getStoreState } from "store/store";

/**
 * Sum 2 counts of instances of waste into a single one.
 */
export function sumTrashCounts(results1: Partial<TrashCount>, results2: Partial<TrashCount>): Partial<TrashCount> {
    const summedResults: Partial<TrashCount> = {};
    Object.entries(results1).forEach(([k, value]) => {
        const key = k as TrashType;
        summedResults[key] = (summedResults[key] || 0) + (value || 0);
    });
    Object.entries(results2).forEach(([k, value]) => {
        const key = k as TrashType;
        summedResults[key] = (summedResults[key] || 0) + (value || 0);
    });
    return summedResults;
}

/**
 * Apply some sorting rules in the context of a collection type to list sorting errors.
 */
export function applySortingRules(sortingRules: Partner["sortingRules"], collectionType: CollectionType) {
    let sortingErrors: SortingRules = {
        ...DEFAULT_SORTING_ERRORS,
        ...sortingRules,
    };

    return Object.values(TrashType).filter(trashType => !sortingErrors[trashType].includes(collectionType));
} 

/**
 * Get the classes of waste that should be considered as errors for a given partner.
 */
export const getSortingErrorClasses = () => {
    const partner = getStoreState().partners.selected.data;
    const collectionType = CollectionType.SORTABLE_WASTE;

    return applySortingRules(partner?.sortingRules, collectionType);
}

/**
 * Simply check if a string matches one of the sorting errors in the current context. 
 * @param sortingErrors List of classes of waste considered as errors in the current context.
 * @param trash The class of waste to consider.
 */
export const isSortingError = (sortingErrors: string[], trash: string) => sortingErrors.includes(trash);


/**
 * For some partners, yellow garbage bags are sorting errors, whereas they're not for others.
 * Therefore we mush merge dynamically some subclasses into larger ones, depending on the partner.
 */
export function getMergingWasteMapping(): MergingWasteMapping {
    return { ...DEFAULT_MERGING_WASTE_MAPPING, };
}

/**
 * Cluster some waste count by merging classes that go together,
 * to create object with keys that can be displayed to the user.
 * For example, the AI detects "big cardboards", but clients consider them as "recyclable waste",
 * so we merge the counts for both classes.
 */
export function mergeTrashCounts(mergingWasteMapping: MergingWasteMapping, count: TrashCount): TrashCount;
export function mergeTrashCounts(mergingWasteMapping: MergingWasteMapping, count: Partial<TrashCount>): Partial<TrashCount>;
export function mergeTrashCounts(mergingWasteMapping: MergingWasteMapping, count: TrashCount | Partial<TrashCount>) {
    let mergedCount: TrashCount | Partial<TrashCount> = {
        ...omit(count, Object.keys(mergingWasteMapping)),
    };

    Object.entries(mergingWasteMapping).forEach(([subType, parentType]) => {
        const subKey = subType as TrashType;
        const subCount = count[subKey];
        if (subCount) mergedCount[parentType] = (mergedCount[parentType] || 0) + subCount;
    });

    return mergedCount;
}

/**
 * Get the list of trash types included under a "super" trash type (displayed to the user).
 * For example, when displaying to the user, we group "glass bottle" and "glass jar" under "glass bottle".
 * @param mergingWasteMapping Object indicating how waste classes should be merged in the current context.
 * @param mergedClass The "super" trash type displayed to the user.
 * @returns A list of trash type (sorting errors) grouped under the "super" trash type.
 */
export function unmergeTrashType(mergingWasteMapping: MergingWasteMapping, mergedClass: TrashType): TrashType[] {
    const mergedKeys = Object.keys(mergingWasteMapping) as TrashType[];
    const mergedTrashTypes = mergedKeys.filter(mergedTrashType => mergingWasteMapping[mergedTrashType] === mergedClass);

    return [...mergedTrashTypes, mergedClass];
}

/** List of classes of sorting errors that are displayed to the client. */
export function getDisplayedErrorsClasses(sortingErrors?: TrashType[], mergingWasteMapping?: MergingWasteMapping) {
    const errorsClasses = sortingErrors ?? getSortingErrorClasses();
    const mergingMapping = mergingWasteMapping ?? getMergingWasteMapping();

    return errorsClasses.filter(sortingError => !Object.keys(mergingMapping).includes(sortingError));
}

/**
 * Create an empty TrashCount (all values at 0)
 */
export function initTrashCount(): TrashCount {
    return {
        [TrashType.RECYCLE_WASTE]: 0,
        [TrashType.BIG_CARDBOARD]: 0,
        [TrashType.BULKY]: 0,
        [TrashType.CABLE]: 0,
        [TrashType.CERAMIC]: 0,
        [TrashType.CHEMICAL]: 0,
        [TrashType.ELECTRONIC_WASTE]: 0,
        [TrashType.GARBAGE_BAG]: 0,
        [TrashType.GAS_CYLINDER]: 0,
        [TrashType.GLASS_BOTTLE]: 0,        
        [TrashType.GLASS_JAR]: 0,
        [TrashType.GREEN_WASTE]: 0,
        [TrashType.ORGANIC_WASTE]: 0,
        [TrashType.POLYSTYRENE]: 0,
        [TrashType.SURGICAL_MASK]: 0,
        [TrashType.TEXTILE]: 0,
        [TrashType.UNDESIRABLE_FIBROUS]: 0,
        [TrashType.OTHER]: 0,
    };
}

/**
 * Calculate the total count of errors in some waste results object
 */
export function getErrorsCount(sortingErrors: TrashType[], results: BatchResults) {
    let count = 0;
    for (const errorType of sortingErrors) {
        const errorCount = results[errorType];
        if (errorCount && errorCount > 0) {
            count += errorCount;
        }
    }
    return count;
}

/**
 * Extract the sorting errors as displayed to the clients from some results.
 * @param sortingErrors List of classes considered as errors.
 * @param mergingWasteMapping Mapping to merge subclasses into parent classes.
 * @param results The result to format.
 */
export function getDisplayedErrors(sortingErrors: TrashType[], mergingWasteMapping: MergingWasteMapping, results: BatchResults): Partial<TrashCount> {
    let errors: Partial<TrashCount> = {};
    for (const errorType of sortingErrors) {
        const errorCount = results[errorType];
        if (errorCount && errorCount > 0) {
            errors[errorType] = errorCount;
        }
    }

    return mergeTrashCounts(mergingWasteMapping, errors);
}