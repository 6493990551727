import { Box, Grid, GridProps, IconButton, Stack, Tooltip } from '@mui/material';
import DatesSelect from 'components/_include/Filters/DatesSelect';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { DatesRangeType, getDatesFromDateSelectType, isSingleDay } from 'helpers/dates';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import ActionButton from 'components/_include/Buttons/ActionButton';
import DownloadImagesToAnnotateDialog from '../DownloadImagesDialog/DownloadImagesToAnnotateDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PartnerAndTruckSelect from './PartnerAndTruckSelect';
import DownloadedImagesSelect, { HIDE_DOWNLOADED_IMAGES } from 'components/_include/Filters/DownloadedImagesSelect';
import AIResultsOffSelect, { SHOW_AI_RESULTS_OFF } from 'components/_include/Filters/AIResultsOffSelect';
import { ImagesToAnnotateActions, selectNumberOfBatchesToDownload } from 'store/reducers/batches/to_download';
import ToggleImagesToDownloadButton from './ToggleImagesToDownloadButton';
import DownloadLimitSelect from './LoadImagesSelect';
import ErrorsSelect from 'components/Map/SortingMap/Filters/ErrorsSelect';
import TimesRangeSelect from 'components/_include/Filters/TimeSelect/TimesRangeSelect';
import BatchesController from 'controllers/batches';
import { TrashType } from 'constants/trash';

type FiltersProps = {

}

const FilterWrapper: React.FC<GridProps> = (props) => (
    <Grid
        item
        justifyContent="center"
        display="flex"
        {...props}
    />
);

function ImagesFilterAndAction(props: FiltersProps) {
    const loading = useAppSelector(state => state.batches.list.loading);
    const imagesSelected = useAppSelector(state => selectNumberOfBatchesToDownload(state) > 0);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.GLOSSARY]);

    const [searchParams, setSearchParams] = useSearchParams();
    const partnerID = searchParams.get("partnerID");
    const truckID = searchParams.get("truckID");
    const dateSelect = searchParams.get("dateSelect");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const startTime = searchParams.get("startTime");
    const endTime = searchParams.get("endTime");
    const trashType = searchParams.getAll("errors") as TrashType[];
    const flattenedTrash = trashType.join("");
    const hideDownloaded = searchParams.get("downloaded");
    const showAIOff = searchParams.get("ai_off");

    const needToSelectDates = dateSelect === DatesRangeType.CUSTOM && (!startDate || !endDate);
    const [downloadLimit, setDownloadLimit] = useState<number>(50);

    /**
     * Is only a single day selected? 
     * (then we display the Times range select)
     */
    const singleDaySelected = useMemo(() => {
        if (!dateSelect) return false;
        return isSingleDay(dateSelect as DatesRangeType, moment(startDate), moment(endDate));
    }, [dateSelect, startDate, endDate]);

    const getImages = () => {
        let filters: Parameters<typeof BatchesController.list>[1] = [];

        if (truckID) { // filter by truck
            filters.push({
                fieldPath: "truckID",
                opStr: "==",
                value: truckID,
            });
        }

        if (dateSelect) { // dates filter
            let start: moment.Moment | undefined;
            let end: moment.Moment | undefined;
            if (dateSelect === DatesRangeType.CUSTOM) { // custom range
                start = startDate ? moment(startDate) : undefined;
                end = endDate ? moment(endDate) : undefined;
            }
            else { // predefined range (eg "last month"...)
                const dates = getDatesFromDateSelectType(dateSelect.toString() as DatesRangeType);
                start = dates.start;
                end = dates.end;
            }

            if (start) {
                if (singleDaySelected && startTime) { // start at a specific time
                    const [startHours, startMinutes] = startTime.split(":");   
                    start.set("hours", Number(startHours)).set("minutes", Number(startMinutes));
                }
                filters.push({
                    fieldPath: "timestamp",
                    opStr: ">=",
                    value: Timestamp.fromDate(start.toDate()),
                });
            }
            if (end) {
                if (singleDaySelected && endTime) { // start at a specific time
                    const [endHours, endMinutes] = endTime.split(":");   
                    end.set("hours", Number(endHours)).set("minutes", Number(endMinutes));
                }
                filters.push({
                    fieldPath: "timestamp",
                    opStr: "<=",
                    value: Timestamp.fromDate(end.endOf("day").toDate()),
                });
            }
        }

        if (hideDownloaded) { // filter images downloaded
            switch (hideDownloaded) {
                case HIDE_DOWNLOADED_IMAGES.TO_ANNOTATE:
                    filters.push({
                        fieldPath: "downloadedToAnnotate",
                        opStr: "==",
                        value: false,
                    });
                    break;

                case HIDE_DOWNLOADED_IMAGES.TO_AUGMENT:
                    filters.push({
                        fieldPath: "downloadedToAugment",
                        opStr: "==",
                        value: false,
                    });
                    break;

                case HIDE_DOWNLOADED_IMAGES.HIDE_ALL:
                    filters.push({
                        fieldPath: "downloadedToAnnotate",
                        opStr: "==",
                        value: false,
                    }, {
                        fieldPath: "downloadedToAugment",
                        opStr: "==",
                        value: false,
                    },);
                    break;
            }
        }

        if (showAIOff === SHOW_AI_RESULTS_OFF.RESULTS_OFF_ONLY) { // only show batches where AI results differ from manual
            filters.push({
                fieldPath: "aiResultsOff",
                opStr: "==",
                value: true,
            });
        }

        // reset selected images to download
        dispatch(ImagesToAnnotateActions.clearList());

        dispatch(BatchesController.list(partnerID, filters, trashType, { fieldPath: "timestamp", directionStr: "asc" }, downloadLimit));
    };

    // reload collections when filters change
    useEffect(() => {
        if (dateSelect) {
            if (dateSelect !== DatesRangeType.CUSTOM || (startDate && endDate)) { // make sure dates are selected
                getImages();
            }
        }
    }, [partnerID, truckID, dateSelect, startDate, endDate, startTime, endTime, flattenedTrash, hideDownloaded, showAIOff, downloadLimit]);

    // controls the opening of the dialog to confirm downloading of images to annotate
    const [downloadDialogOpen, toggleDownloadDialog] = useState(false);


    return (
        <Box
            display="flex"
        >
            <Grid
                container
                rowSpacing={2}
                columnSpacing={1}
                display="flex"
            >
                <FilterWrapper>
                    <PartnerAndTruckSelect
                        parentLoading={loading}
                    />
                </FilterWrapper>

                <FilterWrapper>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <DatesSelect
                                parentLoading={loading}
                                defaultValue={DatesRangeType.THIS_WEEK}
                            />
                        </Grid>

                        {singleDaySelected && (
                            <Grid item>
                                <TimesRangeSelect
                                    parentLoading={loading}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FilterWrapper>

                {/* <FilterWrapper>
                    <ImagesToAnnotateSelect
                        parentLoading={loading}
                    />
                </FilterWrapper> */}

                <FilterWrapper>
                    <DownloadedImagesSelect
                        parentLoading={loading}
                    />
                </FilterWrapper>

                <FilterWrapper>
                    <AIResultsOffSelect
                        parentLoading={loading}
                    />
                </FilterWrapper>

                <FilterWrapper>
                    <ErrorsSelect
                        parentLoading={loading}
                    />
                </FilterWrapper>

                <FilterWrapper>
                    <DownloadLimitSelect
                        value={downloadLimit}
                        onChange={setDownloadLimit}
                    />
                </FilterWrapper>

                <FilterWrapper
                    display="inline-block"
                    flexGrow={1}
                >
                    <IconButton
                        onClick={() => getImages()}
                    >
                        <CachedIcon />
                    </IconButton>
                </FilterWrapper>
            </Grid>

            <Stack
                direction="column"
                spacing={2}
            >
                <ToggleImagesToDownloadButton />
                <Tooltip
                    title={needToSelectDates
                        ? t("select_date_range", { ns: Namespace.GLOSSARY }).toString()
                        : !imagesSelected
                            ? t("select_images_to_annotate", { ns: Namespace.GLOSSARY }).toString()
                            : ""}
                >
                    <span>
                        <ActionButton
                            color='primary'
                            startIcon={<DownloadIcon />}
                            disabled={needToSelectDates || !imagesSelected}
                            onClick={() => toggleDownloadDialog(true)}
                            fullWidth
                        >
                            {t("download", { ns: Namespace.ACTIONS })}
                        </ActionButton>
                    </span>
                </Tooltip>
            </Stack>

            <DownloadImagesToAnnotateDialog
                open={downloadDialogOpen}
                onClose={() => toggleDownloadDialog(false)}
            />
        </Box>
    );
}

export default ImagesFilterAndAction;