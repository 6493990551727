
import { DatesRangeType } from "helpers/dates";
import { getDisplayedErrorsClasses } from "helpers/trash";

/**
 * The surface of the dumpster captured in the image is around 10 square meters
 */
export const REAL_VISIBLE_SURFACE = 10;

export const getDefaultFiltersValues = () => ({
    dates: DatesRangeType.LAST_THREE_MONTHS,
    errors: getDisplayedErrorsClasses(),
});

/** Levels of threat represented by a number of sorting errors */
export enum DangerLevel {
    /** 0 error */
    NONE = "0",
    /** 1-3 errors */
    LOW = "1",
    /** 4-6 errors */
    MEDIUM = "4",
    /** 7-10 errors */
    HIGH = "7",
    /** 11+ errors */
    VERY_HIGH = "11",
};