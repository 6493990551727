import { configureStore, combineReducers } from "@reduxjs/toolkit";
import BatchesListReducer from "./reducers/batches/list";
import BatchesMapReducer from "./reducers/batches/map";
import ToDownloadReducer from "./reducers/batches/to_download";
import CollectionsReducer from "./reducers/collections/list";
import SelectedCollectionReducer from "./reducers/collections/selected";
import CollectionsSchedulesListReducer from "./reducers/collections_schedules/list";
import SelectedCollectionScheduleReducer from "./reducers/collections_schedules/selected";
import SelectedPartnerReducer from "./reducers/partners/selected";
import PartnersReducer from "./reducers/partners/list";
import RoutesReducer from "./reducers/routes/list";
import SnackReducer from "./reducers/snacks";
import TrucksReducer from "./reducers/trucks/list";
import SelectedTruckReducer from "./reducers/trucks/selected";
import UserReducer from "./reducers/user";
import AddressesReducer from "./reducers/saved_addresses/list";
import PlacedBatchesReducer from "./reducers/batches/places";
import SelectedPlaceReducer from "./reducers/saved_addresses/selected";
import AreasReducer from "./reducers/areas/list";
import SelectedAreaReducer from "./reducers/areas/selected";
import AreasMapReducer from "./reducers/areas/map";
import OsmAreasReducer from "./reducers/areas/osm";
import StatsReducer from "./reducers/stats";
import SelectedBatchReducer from "./reducers/batches/selected";
import WastesInputsReducer from "./reducers/batches/wastes_inputs";
import MediaOverlayReducer from "./reducers/batches/media_overlay";
import SortingMapReducer from "./reducers/batches/sorting_map";
import CollaboratorsReducer from "./reducers/collaborators/list";
import SelectedCollaboratorReducer from "./reducers/collaborators/selected";
import CollectionRFIDReducer from "./reducers/collections/rfid";
import ValidationShortcutsReducer, { VALIDATION_PREFERENCES_STORAGE_KEY } from "./reducers/preferences/validation_shortcuts";
import { omit } from "lodash";
import MissionsReducer from "./reducers/missions/list";
import SelectedMissionReducer from "./reducers/missions/selected";
import SortingRulesReducer from "./reducers/sorting_rules";

const store = configureStore({
    reducer: {
        areas: combineReducers({
            list: AreasReducer,
            selected: SelectedAreaReducer,
            map: AreasMapReducer,
            osm: OsmAreasReducer,
        }),
        batches: combineReducers({
            list: BatchesListReducer,
            collectionMap: BatchesMapReducer,
            sortingMap: SortingMapReducer,
            places: PlacedBatchesReducer,
            toDownload: ToDownloadReducer,
            selected: SelectedBatchReducer,
            mediaOverlay: MediaOverlayReducer,
            wastesInputs: WastesInputsReducer,
        }),
        collaborators: combineReducers({
            list: CollaboratorsReducer,
            selected: SelectedCollaboratorReducer,
        }),
        collections: combineReducers({
            list: CollectionsReducer,
            selected: SelectedCollectionReducer,
            rfid: CollectionRFIDReducer,
        }),
        collectionsSchedules: combineReducers({
            list: CollectionsSchedulesListReducer,
            selected: SelectedCollectionScheduleReducer,
        }),
        partners: combineReducers({
            list: PartnersReducer,
            selected: SelectedPartnerReducer,
        }),
        preferences: combineReducers({
            validation: ValidationShortcutsReducer,
        }),
        savedAddresses: combineReducers({
            list: AddressesReducer,
            selected: SelectedPlaceReducer,
        }),
        routes: RoutesReducer,
        snacks: SnackReducer,
        sortingRules: SortingRulesReducer,
        stats: StatsReducer,
        trucks: combineReducers({
            list: TrucksReducer,
            selected: SelectedTruckReducer,
        }),
        user: UserReducer,
        mission: combineReducers({
            list: MissionsReducer,
            selected: SelectedMissionReducer,
        }), 
    },
});

/** Save the user's preferences in the local storage on every update. */
store.subscribe(() => {
    localStorage.setItem(
        VALIDATION_PREFERENCES_STORAGE_KEY, 
        JSON.stringify(
            omit(store.getState().preferences.validation, ["loading", "error", "dialogOpen",])
        )
    );
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type LoadableContext = {
    loading: boolean;
    error: string | null;
}

export const getStoreState = () => store.getState();