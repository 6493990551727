import { getPictureURL, StorageType } from "helpers/storage";
import { useCallback, useEffect, useState } from "react";
import DemoAIResults from "./DemoAIResults";
// import DemoAIResults from "./DemoAIResults";

type DemoBatchImageProps = {
    imageURL: string;
};

function DemoBatchImage(props: DemoBatchImageProps) {
    const { imageURL, } = props;

    const [originalImageDimensions, setOriginalImageDimensions] = useState({
        width: 600,
        height: 600 / 1.5,
        ratio: 1.5,
    });

    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const handleImageRef = useCallback((node: HTMLImageElement) => {
        setImage(node);
    }, []);

    useEffect(() => {
        if (image) {
            image.onload = () => {
                setOriginalImageDimensions({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                    ratio: image.naturalWidth / image.naturalHeight,
                });
            }
        }
    }, [image]);

    const [pictureURL, setPictureURL] = useState("");

    useEffect(() => {
        getPictureURL(imageURL, StorageType.BATCHES)
            .then(url => {
                if (url) setPictureURL(url);
            });
    }, [imageURL]);

    return (
        <div style={{
            width: "100%",
            aspectRatio: `${originalImageDimensions.ratio}`,
            position: "relative",
            maxHeight: "calc(100vh - 40px)",
        }}>
            <img
                ref={handleImageRef}
                alt=""
                src={pictureURL}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: '100%',
                    maxHeight: '100%',
                    objectFit: "contain",
                }} />
            {image !== null && (
                <DemoAIResults
                    imageRef={image}
                />
            )}
        </div>
    );
};

export default DemoBatchImage;