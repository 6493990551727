import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useIsAdmin, useSortingRules } from "store/hooks";
import { selectCollectionById } from "store/reducers/collections/list";
import { getDisplayedErrors } from "helpers/trash";
import Collection from "models/Collection";
import { blueGrey } from "@mui/material/colors";
import SectionLoader from "components/_include/SectionLoader";
import { useMemo, useRef } from "react";
import { getGradient } from "helpers/draw";
import BinsCountsLabel from "../../../_include/BinsCountsLabel";
import MarkCollectionAsDoneButton from "./MarkCollectionAsDoneButton";
import BatchesController from "controllers/batches";
import CollectionScheduleLabel from "./CollectionScheduleLabel";

type ItemProps = {
    collectionID: string;
    onClick: (collection: Collection, itemOffset: number, itemWidth: number) => void;
}

const COLLECTION_ITEM_WIDTH = 50;
export const COLLECTION_ITEM_HEIGHT = 30;
const SELECTED_SCALE = 1.2;

export default function CollectionListItem(props: ItemProps) {
    const { collectionID, onClick } = props;

    const isAdmin = useIsAdmin();

    const { errorsClasses, mergingMapping } = useSortingRules();

    const { t } = useTranslation([Namespace.COLLECTIONS, Namespace.GLOSSARY, Namespace.WASTES]);

    const collection = useAppSelector(state => selectCollectionById(state, collectionID)!);
    const loading = useAppSelector(state => state.collections.selected.loading || state.batches.list.loading);
    const selected = useAppSelector(state => state.collections.selected.data?.ID === collectionID);
    const scale = selected ? SELECTED_SCALE : 1;

    const dispatch = useAppDispatch();

    const errorsString = useMemo(() => {
        return Object.entries(getDisplayedErrors(errorsClasses, mergingMapping, collection.results))
            .map(([error, count]) => `${t(error, { ns: Namespace.WASTES })}: ${count}`)
            .join(", ");
    }, [collection.results, t]);

    /** Quality as portion of the images that don't contain an error. */
    const quality = collection.batchesWithErrorCount !== undefined && collection.batchesCount > 0 ?
        (collection.batchesCount - collection.batchesWithErrorCount) / collection.batchesCount
        : undefined;

    const cardRef = useRef<HTMLDivElement>(null);

    const disabledUntilProcessed = !isAdmin && !collection.processed;

    const handleClick = () => {
        if (loading || disabledUntilProcessed) return; // prevent click while loading

        if (selected) { // collection already selected: reset filters
            dispatch(BatchesController.applyMapBatchesFilters([], "collection"));
        }
        else { // select collection
            onClick(collection, cardRef.current!.offsetLeft, cardRef.current!.offsetWidth);
        }
    }

    return (
        <Card
            ref={cardRef}
            {...(selected && { elevation: 6 })}
            sx={{
                position: "relative",
                width: theme => theme.spacing(COLLECTION_ITEM_WIDTH * scale),
                height: theme => theme.spacing(COLLECTION_ITEM_HEIGHT * scale),
                ...(selected ? { // selected style
                    background: theme => getGradient("vertical", theme.palette.info.main, theme.palette.secondary.main),
                } : { // not selected style
                    backgroundColor: blueGrey[200]
                }),
                color: "#ffffff",
                cursor: loading || disabledUntilProcessed ? "initial" : "pointer"
            }}
            onClick={handleClick}
        >
            <CardContent sx={{ px: 3.5, py: 2.5, }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <CollectionScheduleLabel collectionID={collectionID} />


                    {isAdmin && ( // if admin, display xx/yy images received and "Done"/"Undo" button 
                        <Box
                            display="flex"
                            alignSelf="flex-end"
                            alignItems="center"
                            gap={1}
                            border={`1px solid #fff`}
                            borderRadius={1}
                            pl={2}
                        >
                            <Typography
                                variant="body2"
                                whiteSpace="nowrap"
                            >
                                {t(collection.batchesLeft ? "images_received_count" : "images_count", {
                                    ns: Namespace.GLOSSARY,
                                    ...(collection.batchesLeft ? {
                                        received: collection.batchesCount,
                                        count: collection.batchesCount + collection.batchesLeft,
                                    } : {
                                        count: collection.batchesCount,
                                    })
                                })}
                                {(collection.batchesLeft ?? 0) > 0 && " ⚠️"}
                            </Typography>

                            <MarkCollectionAsDoneButton
                                loading={loading}
                                collection={collection}
                            />
                        </Box>
                    )}
                </Box>

                <Typography
                    fontSize={20}
                    fontWeight="bold"
                >
                    {t("date_full_day", { ns: Namespace.DATES, date: collection.startAt })}
                </Typography>

                {(collection.processed || isAdmin) && (
                    collection.binsCounts ? ( // display the number of bins
                        <BinsCountsLabel binsCounts={collection.binsCounts} />
                    ) : ( // display the number of images captured
                        <Typography
                            variant="body2"
                            sx={{
                                opacity: 0.9,
                            }}
                        >
                            {t("batches_count", {
                                ns: Namespace.COLLECTIONS,
                                count: collection.batchesCount,
                            })}
                        </Typography>
                    )
                )}


                <Divider
                    sx={{
                        borderColor: "rgba(255, 255, 255, 0.2)",
                        my: 1.5,
                    }}
                />

                {(collection.processed || isAdmin) && (
                    <Typography
                        variant="body2"
                        fontStyle="italic"
                        fontSize={13.5}
                        sx={{ mb: 1 }}
                    >
                        {errorsString}
                    </Typography>
                )}

                {collection.processed && quality !== undefined ?
                    <Box>
                        <Typography
                            component="span"
                            variant="body1"
                            fontWeight="bold"
                            flexGrow={1}
                        >
                            {t("quality_percentage", { ns: Namespace.GLOSSARY, count: Math.round(quality * 100) })}
                        </Typography>
                        {` `}
                        <Typography
                            component="span"
                            variant="body2"
                            flexGrow={1}
                        >
                            {t("error_ratio", { ns: Namespace.GLOSSARY, errorImagesCount: collection.batchesWithErrorCount, totalImagesCount: collection.batchesCount })}
                        </Typography>
                    </Box>
                    :
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        flexGrow={1}
                    >
                        {t("processing", { ns: Namespace.COLLECTIONS })}
                    </Typography>
                }

                {selected && loading && (
                    <SectionLoader />
                )}
            </CardContent>
        </Card>
    )
}