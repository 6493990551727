import Batch from "models/Batch";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { getDisplayedErrors, getErrorsCount, sumTrashCounts } from "helpers/trash";
import { NamedErrorsByAddress } from "constants/types";
import { formatAddress } from "helpers/geo";
import { MergingWasteMapping, TrashType } from "constants/trash";

export type BatchesByPlace = NamedErrorsByAddress & {
    batches: Batch[];
}

const batchesPlacesAdapter = createEntityAdapter<BatchesByPlace>({
    selectId: p => p.addressKey,
    sortComparer: (p1, p2) => p2.errorsCount - p1.errorsCount, // sort by timestamp desc
});

type BatchesByPlaceContext = {
    selectedPlace: BatchesByPlace | null;
    // count: number;
    // next: NextQuery<BatchDataDb> | null;
    // hoveredBatchID: string | undefined;
    // selectedBatch: BatchData | undefined;
    // selectedClusterID: string | undefined;
    // selectedBatchesIDs: string[] | undefined;
    // selectedClusterColor: ClusterColor | undefined;
    // selectedClusterErrors: ErrorsCount;
};

const initialState: BatchesByPlaceContext = {
    selectedPlace: null,
    // count: 0,
    // loading: false,
    // error: null,
    // next: null,
    // hoveredBatchID: undefined,
    // selectedBatch: undefined,
    // selectedClusterID: undefined,
    // selectedBatchesIDs: undefined,
    // selectedClusterColor: undefined,
    // selectedClusterErrors: {},
};


export const getBatchKey = (batch: Pick<Batch, "hereID" | "address">): string => {
    if (batch.hereID) {
        return batch.hereID;
    } else if (batch.address) {
        return formatAddress(batch.address);
    } else {
        throw new Error("Batch has neither hereID nor address");
    }
};

export const batchesSlice = createSlice({
    name: 'batches_by_places',
    initialState: batchesPlacesAdapter.getInitialState(initialState),
    reducers: {
        setList: (state, { payload: { batches, errorsClasses, mergingMapping } }: PayloadAction<{ batches: Batch[], errorsClasses: TrashType[], mergingMapping: MergingWasteMapping }>) => {
            let batchesByPlace: { [addressKey: string]: BatchesByPlace } = {};
            for (let batch of batches) {
                if (!batch.address) continue; // skip batches with address not looked up yet
                const addressKey = getBatchKey(batch);
                let placeBatches: BatchesByPlace = batchesByPlace[addressKey];
                if (!placeBatches) { // initialize empty batches' place stats
                    placeBatches = {
                        addressKey: addressKey,
                        address: batch.address,
                        batches: [],
                        errorsCount: 0,
                        errors: {},
                    };
                }
                placeBatches.batches.push(batch); // add the batch to list of place's batches
                placeBatches.errorsCount += getErrorsCount(errorsClasses, batch.results); // add the batches errors to the place's errors count
                placeBatches.errors = sumTrashCounts(placeBatches.errors, getDisplayedErrors(errorsClasses, mergingMapping, batch.results));
                batchesByPlace[addressKey] = placeBatches;
            }
            batchesPlacesAdapter.setAll(state, Object.values(batchesByPlace));
        },
        selectPlace: (state, { payload: placeID }: PayloadAction<string | null>) => {
            if (placeID === null) state.selectedPlace = null;
            else state.selectedPlace = state.entities[placeID] ?? null;
        },
    },
});

export const PlacedBatchesActions = batchesSlice.actions;

export const {
    selectAll: selectAllPlacedBatches,
    selectById: selectPlacedBatchesByID,
    selectIds: selectPlacedBatchesIDs,
} = batchesPlacesAdapter.getSelectors((state: RootState) => state.batches.places);

const PlacedBatchesReducer = batchesSlice.reducer;

export default PlacedBatchesReducer;