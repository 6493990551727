import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import MissionsMethods from 'controllers/mission';
import { Timestamp } from 'firebase/firestore';
import { Namespace } from 'locales/translations';
import { MissionDbData, MissionStatus } from 'models/Missions';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'react-rainbow-components';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import { showSuccess } from 'store/reducers/snacks';

type CreateMissionModalProps = {
    onMissionCreated: undefined;
} | {
    onMissionCreated: (mission: MissionDbData | null) => void;
}

const CreateMissionModal: React.FC<CreateMissionModalProps> = ({ onMissionCreated }) => {
    const [name, setName] = useState<string>('');
    const [deadline, setDeadline] = useState<moment.Moment | undefined>();
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();
    const validForm = name && deadline && partnerID;
    const saveing =  useAppSelector(state => state.mission.selected.loading);

    const { t } = useTranslation([Namespace.SNACKS, Namespace.MISSIONS, Namespace.ACTIONS, Namespace.CONTACTS]);

    const handleSave = useCallback(async () => {
        if (!validForm) return;

        const missiondata: MissionDbData = {
            ID: '',
            name: name,
            deadline: Timestamp.fromDate(deadline.toDate()),
            assigned: [],
            addresses: [],
            status: MissionStatus.PENDING
        };

        const mission = await dispatch(MissionsMethods.create(missiondata, partnerID));

        if (mission) {
            dispatch(showSuccess(t("missions.create.success", { ns: Namespace.SNACKS, name: mission.name })));
            if (onMissionCreated) onMissionCreated(mission);
        }
    }, [validForm, name, deadline, dispatch, partnerID, onMissionCreated, t]);

    const handleDateChange = useCallback((value: Date) => {
        setDeadline(moment(value));
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    return (
        <Card
            sx={{
                width: 600,
                height: 442,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}>
            <CardContent
                sx={{
                    maxWidth: 400,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h2" sx={{ mt: 2 }}>
                        {t("create_new_mission", { ns: Namespace.MISSIONS })}
                    </Typography>
                </Box>
                <TextField
                    label={t("name", { ns: Namespace.CONTACTS })}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={name}
                    onChange={handleNameChange}
                />
                <DatePicker
                    minDate={new Date()}
                    disabled={false}
                    placeholder={t("select_dates.single", { ns: Namespace.ACTIONS })}
                    formatStyle="large"
                    locale="fr"
                    value={deadline?.toDate()}
                    onChange={(value: Date) => handleDateChange(value)}
                    className="datepicker-single"
                />
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 2 }}>
                <Button
                    variant="text"
                    color='secondary'
                    sx={{ mr: 2 }}
                    onClick={() => onMissionCreated?.(null)}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    variant="text"
                    color="success"
                    disabled={!validForm}
                    onClick={handleSave}
                    loading={saveing}
                >
                    {t("save", { ns: Namespace.ACTIONS })}
                </LoadingButton>
            </Box>
        </Card>
    );
};

export default CreateMissionModal;
