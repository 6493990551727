import { ResponsiveBar } from "@nivo/bar";
import SectionLoader from 'components/_include/SectionLoader';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { TrashCount, TrashType, WASTES_COLORS } from 'constants/trash';
import { REAL_VISIBLE_SURFACE } from 'constants/stats';
import ChartTitle from './ChartTitle';
import { useAppSelector, useSortingRules } from "store/hooks";
import { DatumValue } from "@nivo/core";
import TrashColorSquare from "components/_include/TrashColorSquare";
import { stringToTimestamp } from "helpers/dates";

type ErrorsSurfaceEvolutionBarChartProps = {

}

type ColorID = `${TrashType}Color`;

type ColorsMap = Partial<{ [colorId in ColorID]: string }>;

type ErrorPoint = {
    date: number;
} & Partial<TrashCount> & ColorsMap;

const TICK_WIDTH = 120;

function ErrorsSurfaceEvolutionBarChart(props: ErrorsSurfaceEvolutionBarChartProps) {
    const errorsSurfaces = useAppSelector(state => state.stats.data?.errorsSurfaces);

    const loading = useAppSelector(state => state.trucks.list.loading
        || state.areas.list.loading || state.stats.loading || state.collections.list.loading);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.STATS, Namespace.WASTES]);

    const theme = useTheme();

    const { displayedErrors } = useSortingRules();

    const data: ErrorPoint[] = useMemo(() => {
        let errorsEvolution: ErrorPoint[] = [];
        if (!errorsSurfaces) return errorsEvolution; // results not loaded yet

        errorsSurfaces.forEach(errorsSurfacesDated => {
            let surfaces: { [errorType: string]: number } = {};
            let colors: ColorsMap = {};
            for (const errorType of displayedErrors) {
                const surface = errorsSurfacesDated[errorType];
                const value = surface ? Math.round(surface * REAL_VISIBLE_SURFACE * 10) / 10 : 0;
                if (value > 0) {
                    surfaces[errorType] = value;
                    colors[`${errorType}Color`] = WASTES_COLORS[errorType];
                }
            }
            errorsEvolution.push({
                date: stringToTimestamp(errorsSurfacesDated.date).toMillis(),
                ...surfaces,
                ...colors,
            });
        });

        return errorsEvolution;
    }, [errorsSurfaces]);

    // adapt bottom axis ticks to chart width to prevent overlap
    const wrapperRef = useRef<HTMLDivElement>();
    const wrapperWidth = wrapperRef.current?.offsetWidth;
    const [bottomTickValues, setBottomTickValues] = useState<DatumValue[]>([]);
    useEffect(() => {
        if (!wrapperWidth) setBottomTickValues([]);
        else {
            var xValues = data.map((d) => d.date);

            var gridWidth = Math.ceil(wrapperWidth / xValues.length);
            var tickDistance = Math.floor(TICK_WIDTH / gridWidth);

            setBottomTickValues(tickDistance === 0 ? xValues : xValues.filter((_, i) => i % tickDistance === 0));
        }
    }, [TICK_WIDTH, wrapperWidth, data]);

    return (
        <Box
            justifyContent="center"
            my={2}
        >
            <ChartTitle>
                {t("errors_surface_evolution.title", { ns: Namespace.CHARTS })}
            </ChartTitle>
            <Box
                ref={wrapperRef}
                width="100%"
                height={theme => theme.spacing(38)}
                position="relative"
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    <ResponsiveBar
                        data={data}
                        keys={displayedErrors}
                        indexBy="date"
                        margin={{ top: 24, right: 16, bottom: 40, left: 16 }}
                        colors={({ id, data }) => data[`${id.toString()}Color` as ColorID]!}
                        indexScale={{ type: 'band' }}
                        valueScale={{ type: 'linear' }}
                        valueFormat={value => `${value} m\u00b2`}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickValues: bottomTickValues,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: t('errors_surface_evolution.bottomAxis', { ns: Namespace.CHARTS }),
                            legendOffset: 36,
                            legendPosition: 'middle',
                            format: (tick) => t("date_day", { ns: Namespace.DATES, date: tick }),
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: t('errors_surface_evolution.leftAxis', { ns: Namespace.CHARTS }),
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        labelSkipWidth={28} // less than 28px wide or 1rem height: bar too small to display label
                        labelSkipHeight={theme.typography.fontSize}
                        labelTextColor={(barDatum) => {
                            if (barDatum.color === WASTES_COLORS[TrashType.TEXTILE]) { // darker text when on light pink
                                return "rgba(0,0,0,.7)";
                            }

                            return "#FFFFFF";
                        }}
                        tooltip={({ data, color, id, formattedValue, }) => (
                            <Card
                                sx={{
                                    background: "white",
                                    padding: "9px 12px",
                                    border: "1px solid #ccc",
                                    borderRadius: 2,
                                }}
                            >
                                <Box>
                                    {t("date_full_day", { ns: Namespace.DATES, date: data.date})}
                                </Box>
                                <Box
                                    py="3px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Box component="span" display="inline-flex" mr={1} mb="1px">
                                        <TrashColorSquare trashType={id as TrashType} />
                                    </Box>
                                    <strong>{t(id.toString(), { ns: Namespace.WASTES })}</strong>: {formattedValue}
                                </Box>
                            </Card>
                        )}
                    />
                )}
            </Box>
        </Box>
    );
}

export default ErrorsSurfaceEvolutionBarChart;