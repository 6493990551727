import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { MissionsAddressWithBatchCount } from 'constants/types';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getBatchKey } from 'store/reducers/batches/places';
import { selectAllSortingPoints } from 'store/reducers/batches/sorting_map';
import { SelectedMissionActions } from 'store/reducers/missions/selected';
import PlacesSearchBar from './PlacesSearchBar';

type PlacesListDrawerHeaderProps = {
    showForMissions: boolean;
}

export default function PlacesListDrawerHeader({ showForMissions }: PlacesListDrawerHeaderProps) {

    const { t } = useTranslation([Namespace.COLLECTIONS, Namespace.GLOSSARY, Namespace.MISSIONS]);

    const dispatch = useAppDispatch();

    const totalBatchesCount = useAppSelector(state => state.batches.sortingMap.totalBatchesCount);

    const addressesSelectedCount = useAppSelector(state => state.mission.selected.selectedMission?.addresses?.length);

    const sortingPoints = useAppSelector(state => selectAllSortingPoints(state))

    const allSelected = addressesSelectedCount === sortingPoints.length && sortingPoints.length > 0;

    const handleSelectAllChange = () => {
        if (allSelected) {
            dispatch(SelectedMissionActions.setAddresses([]));
        } else {
            const alladdresses = sortingPoints.map(sortingPoint => {

                const addressKey = getBatchKey({
                    hereID: sortingPoint.properties.hereID,
                    address: sortingPoint.properties.address,
                });

                const addressData: MissionsAddressWithBatchCount = {
                    address: sortingPoint.properties.address,
                    addressKey,
                    errors: sortingPoint.properties.errors,
                    errorsCount: sortingPoint.properties.errorsCount,
                    lat: sortingPoint.geometry.coordinates[0],
                    lng: sortingPoint.geometry.coordinates[1],
                    batchesCount: sortingPoint.properties.batchesCount,
                };

                return addressData;
            });
            dispatch(SelectedMissionActions.setAddresses(alladdresses));
        }
    };

    return (
        <Box
            bgcolor={theme => theme.palette.secondary.main}
            px={2}
            py={1.5}
            display="flex"
            flexDirection="column"
            gap={1.5}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
            >
                <Typography
                    variant='body1'
                    color="#ffffff"
                    fontWeight="bold"
                >
                    {showForMissions ? t("addresses with most errors", { ns: Namespace.MISSIONS }) : t("bins_addresses", { ns: Namespace.GLOSSARY })}
                </Typography>
                {showForMissions && (
                    <FormControlLabel label={<Typography variant="body2" style={{ color: '#ffff' }}>{t("select_all_addresses", { ns: Namespace.MISSIONS })}</Typography>} control={
                        <Checkbox
                            checked={allSelected}
                            indeterminate={!allSelected && addressesSelectedCount! > 0}
                            onChange={handleSelectAllChange}
                            sx={{
                                color: '#ffffff',
                                '& .MuiSvgIcon-root': {
                                    color: '#ffffff',  // Change the tick (checkmark) color
                                }, padding: 0, mx: 1
                            }}
                        />}
                    />
                )}
                <Typography
                    variant='body2'
                    color="#ffffff"
                >
                    {showForMissions ? t("selected", { ns: Namespace.MISSIONS, count: addressesSelectedCount }) : t("batches_count", { ns: Namespace.COLLECTIONS, count: totalBatchesCount })}
                </Typography>
            </Box>
            <PlacesSearchBar />
        </Box>
    );
}