import { Box, Card, CardContent, Typography } from '@mui/material';
import { getPictureURL, StorageType } from 'helpers/storage';
import { Namespace } from 'locales/translations';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectMissionById } from 'store/reducers/missions/list';

type MissionCardProps = {
    missionID: string;
};

function MissionCard({ missionID }: MissionCardProps) {
    const { t } = useTranslation([Namespace.MISSIONS]);
    
    const { search } = useLocation();

    const mission = useAppSelector(state => selectMissionById(state, missionID));
    const [pictureURL, setPictureURL] = useState<string | null>(null);

    useEffect(() => {
        if (mission?.mapImageURL) {
            getPictureURL(mission.mapImageURL, StorageType.MISSIONS)
                .then((picURL) => {
                    setPictureURL(picURL);
                });
        } else {
            setPictureURL(null);
        }
    }, [mission?.mapImageURL]);

    if (!mission) return null;

    const { name, addresses, createdAt, deadline, assigned } = mission;
    const assignedTo = assigned?.map(user => user.name).join(', ') || 'N/A';

    return (
        <Link to={{
            pathname: `${missionID}/edit`,
            search: search
        }}>
            <Card style={{ cursor: 'pointer' }}>
                <CardContent>
                {pictureURL ? (
                        <Box
                            component="img"
                            sx={{
                                height: 140,
                                width: '100%',
                                mb: 2,
                                objectFit: 'cover'
                            }}
                            src={pictureURL}
                        />
                    ) : (
                        <Box
                            sx={{
                                height: 140,
                                width: '100%',
                                mb: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #ddd',
                                backgroundColor: '#f5f5f5'
                            }}
                        >
                            <Typography variant="body2" color="text.secondary">
                                {t('please_select_an_address', { ns: Namespace.MISSIONS,})}
                            </Typography>
                        </Box>
                    )}
                    <Typography variant="h4" component="div">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('created at')} {moment(createdAt).format('LL')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('addresses_to_visit', { ns: Namespace.MISSIONS, count: addresses?.length ?? 0 })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('deadline')}: {moment(deadline).format('LL')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('assigned to')}: {assignedTo}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );
}

export default MissionCard;
