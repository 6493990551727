import { Box, List, Typography } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import SelectedAddressListItem from './SelectedAddressListItem';

const SelectedAddressesList: React.FC = () => {
    const { t } = useTranslation([Namespace.MISSIONS]);

    const addresses = useAppSelector(state => state.mission.selected.selectedMission?.addresses);

    return (
        <Box flexGrow={1} maxHeight="50vh" display="flex" flexDirection="column" justifyContent="flex-start">
            {addresses === undefined ? (
                <SectionLoader />
            ) : addresses.length === 0 ? (
                <Box flexGrow={1} display="flex" flexDirection="column">
                    <Typography fontStyle="italic" color="rgba(0,0,0,0.36)">{t('no_address_found', { ns: Namespace.MISSIONS })}</Typography>
                </Box>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom>
                        {t('selected_addresses', { ns: Namespace.MISSIONS, count: addresses.length })}
                    </Typography>
                    <Box sx={{ overflowY: 'auto', maxHeight: "45vh"}}>
                        <List>
                            {addresses.map(address => (
                                <SelectedAddressListItem key={address.addressKey} address={address} />
                            ))}
                        </List>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default SelectedAddressesList;
