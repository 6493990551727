import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, ButtonBase, IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import BatchesController from "controllers/batches";
import { getPictureURL, StorageType } from "helpers/storage";
import { getDisplayedErrors } from "helpers/trash";
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, usePartnerID, useSortingRules, } from "store/hooks";
import { BatchesActions, selectBatchById } from "store/reducers/batches/list";
import SectionLoader from '../_include/SectionLoader';
import BatchImageItemAction from "./BatchImageItemAction";
import BatchVerifiedBaddge from "./BatchVerifiedBadge";

type BatchImageItemProps = {
    batchID: EntityId;
}

function BatchImageItem(props: BatchImageItemProps) {
    const { batchID, } = props;

    const batch = useAppSelector(state => selectBatchById(state, batchID)!);

    const partnerID = usePartnerID();

    const { errorsClasses, mergingMapping } = useSortingRules();

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.DATES, Namespace.GLOSSARY, Namespace.STATS, Namespace.WASTES]);

    const [imageURL, setImageURL] = useState<string | null>(null);

    useEffect(() => {
        getPictureURL(batch.imageURL ,StorageType.BATCHES)
            .then(url => {
                setImageURL(url || "");
            });
    }, [batch.imageURL]);

    const handleVisibilityClick = () => {
        if (partnerID) {
            dispatch(BatchesController.update(partnerID, batch.ID, {
                display: !batch.display,
            }));
        }
    }

    const getErrorsLabel = () => {
        const errors = getDisplayedErrors(errorsClasses, mergingMapping, batch.results);
        const errorsString = Object.entries(errors)
            .map(([error, count]) => `${t(error, { ns: Namespace.WASTES })}: ${count}`)
            .join(", ");
        return errorsString;
    }

    const handleImageClick = () => {
        dispatch(BatchesActions.selectBatch(batch.ID));
    }

    return (
        <ImageListItem sx={{ border: `1px solid #ffffff`, }}>
            {(imageURL === null || batch.loading) && (
                <SectionLoader />
            )}
            {imageURL !== null && (
                <ButtonBase
                    onClick={handleImageClick}
                    sx={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                    <img
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        src={imageURL}
                        loading="lazy"
                    />
                </ButtonBase>
            )}

            <ImageListItemBar
                position="top"
                title={t("datetime_full_seconds", { ns: Namespace.DATES, datetime: batch.timestamp })}
                subtitle={getErrorsLabel()}
                actionIcon={<BatchImageItemAction batchID={batchID.toString()} />}
                sx={{
                    "& .MuiImageListItemBar-title": {
                        fontSize: "0.925rem",
                    },
                    "& .MuiImageListItemBar-subtitle": {
                        whiteSpace: "unset", // prevent wrapping of subtitle
                    }
                }}
            />

            {batch.verified && <BatchVerifiedBaddge autoVerified={batch.autoVerified} />}
            
            <Box position="absolute" right={0} bottom={0}>
                <IconButton
                    disabled={batch.loading}
                    sx={{
                        color: 'rgba(255, 255, 255, 0.9)'
                    }}
                    onClick={handleVisibilityClick}
                >
                    {batch.display ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                    {batch.loading && (<SectionLoader />)}
                </IconButton>
            </Box>
        </ImageListItem >
    );
}

export default BatchImageItem;