import { DbCollection } from "constants/db";
import urls from "constants/urls";
import { DocumentData, DocumentSnapshot, GeoPoint, QueryDocumentSnapshot, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getCollectionRef, getDocumentReference } from "helpers/db";
import i18next from "i18next";
import { Namespace } from "locales/translations";
import _ from "lodash";
import Partner, { NewPartnerData, PartnerDbData } from "models/Partner";
import { AreasMapActions } from "store/reducers/areas/map";
import { BatchesMapActions } from "store/reducers/batches/map";
import { SortingMapActions } from "store/reducers/batches/sorting_map";
import { PartnersActions } from "store/reducers/partners/list";
import { SelectedPartnerActions } from "store/reducers/partners/selected";
import { showError } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import { fetchAPI } from "./actions";
import { SortingRulesActions } from "store/reducers/sorting_rules";

/**
 * Serialize a Partner's data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Partner;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): Partner | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot < DocumentData > | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as PartnerDbData;

    const viewport = data.defaultMapViewport;
    const partnerData: Partner = {
        ...data,
        ID: dbDoc.id,
        defaultMapViewport: viewport ? { 
            ...viewport,
            center: { lat: viewport.center.latitude, lng: viewport.center.longitude },
        } : undefined,
    };

    return partnerData;
}

const create = (partnerData: NewPartnerData) => async (dispatch: AppDispatch) => {
    dispatch(SelectedPartnerActions.startLoading());
    
    try {
        const partnersCollectionRef = getCollectionRef([DbCollection.PARTNERS]);
        const partnerDoc = doc(partnersCollectionRef);
        const partner = {
            ID: partnerDoc.id,
            ...partnerData,
        };
        await setDoc(partnerDoc, partner);
        dispatch(PartnersActions.addItem(partner));
        dispatch(SelectedPartnerActions.stopLoading());
        return partner;
    }
    catch (e) {
        const { message } = e as Error;
        console.error("Couldn't create partner", message);
        dispatch(SelectedPartnerActions.setError(message));
        dispatch(showError(i18next.t("partners.create.error", { ns: Namespace.SNACKS, name: partnerData.name })));
        return null;
    }
}
/**
  * Retrieves a list of partners.
  * @param {AppDispatch} dispatch - The dispatch function from the Redux store.
  * @returns {Promise<Partner[]>} The list of partners.
 */
const getPartners = () => async (dispatch: AppDispatch) => {
    dispatch(PartnersActions.startLoadingList());

    try {
        const partners: Partner[] = await fetchAPI(urls.HTTP_FUNCTIONS.listPartners);
        dispatch(PartnersActions.setList(partners));        
        return partners;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed loading partners");
        console.error(error);
        dispatch(PartnersActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
};

const retrievePartner = (partnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedPartnerActions.startLoading());
    
    try {
        const docRef = getDocumentReference(partnerID, DbCollection.PARTNERS);
        const partnerDoc = await getDoc(docRef);
        if (!partnerDoc.exists()) {
            throw new Error(i18next.t("not_found", { ns: Namespace.SNACKS }));
        }

        const partner = fromDbDoc(partnerDoc);

        dispatch(select(partner));

        return [partner];
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed loading partner");
        console.error(error);
        dispatch(SelectedPartnerActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
};

const updatePartner = (partnerID: string, data: Partial<Partner>) => async (dispatch: AppDispatch) => {
    dispatch(SelectedPartnerActions.startLoading());

    // deserialize data before saving it to Firestore
    const { defaultMapViewport: defaultMapBounds } = data;
    const dbData: Partial<PartnerDbData> = {
        ..._.omit(data, "defaultMapViewport"),
        ...(defaultMapBounds && {
            defaultMapViewport: {
                ...defaultMapBounds,
                center: new GeoPoint(defaultMapBounds.center.lat, defaultMapBounds.center.lng),
            },
        }),
    };
    
    try {
        await updateDoc(getDocumentReference(partnerID, DbCollection.PARTNERS), dbData);
        dispatch(PartnersActions.updateItem({ ID: partnerID, data: data }));
        dispatch(SelectedPartnerActions.update(data));
        return true;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed updating partner");
        console.error(error);
        dispatch(SelectedPartnerActions.setError(error.message));
        dispatch(showError(error.message));
        return false;
    }
};

const select = (partner: Partner) => (dispatch: AppDispatch) => {
    dispatch(SelectedPartnerActions.setSelected(partner));
    dispatch(SortingRulesActions.setRules(partner.sortingRules)); // apply the partner's sorting rules to the app

    const viewport = partner.defaultMapViewport;
    if (viewport) {
        // set default center for the map to partner's preference
        dispatch(AreasMapActions.setCenterAndZoom(viewport));
        dispatch(BatchesMapActions.setCenterAndZoom(viewport));
        dispatch(SortingMapActions.setCenterAndZoom(viewport));
    }
};

const PartnersController = {
    create,
    getPartners,
    retrieve: retrievePartner,
    update: updatePartner,
    select,
};

export default PartnersController;