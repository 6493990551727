
import MapIcon from '@mui/icons-material/Map';
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';
import BinsCountsLabel from 'components/_include/BinsCountsLabel';
import { TrashType, WASTES_COLORS } from "constants/trash";
import BatchesController from 'controllers/batches';
import { getAlertColor } from 'helpers/draw';
import { getPictureURL, StorageType } from 'helpers/storage';
import { getDisplayedErrors, getErrorsCount } from 'helpers/trash';
import { Namespace } from 'locales/translations';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector, useSortingRules } from 'store/hooks';
import BatchDetailsActions from './BatchDetailsAdminActions';
import BatchPictureAndMasks from './BatchPictureAndMasks';

type BatchDetailsBodyProps = {
    placeAddress: string;
}

/**
 * Content of the Batches drawer appearing on the side of the maps when a single Batch is selected
 */
function BatchDetailsBody({ placeAddress }: BatchDetailsBodyProps) {

    const { errorsClasses, mergingMapping, } = useSortingRules();

    const batch = useAppSelector(state => state.batches.list.selectedBatch);

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.COMMONS, Namespace.COLLECTIONS, Namespace.DATES, Namespace.GLOSSARY, Namespace.WASTES]);

    const { latitude, longitude } = batch?.position || { latitude: undefined, longitude: undefined };

    const [pictureURL, setPictureURL] = useState<string | null>(null);

    useEffect(() => {
        // get batch's image signed URL
        if (batch?.imageURL) {
            getPictureURL(batch.imageURL, StorageType.BATCHES)
                .then((picURL) => {
                    setPictureURL(picURL);
                });
        }
    }, [batch]);

    if (!batch) {
        return (
            <Box />
        );
    }

    const errors = getDisplayedErrors(errorsClasses, mergingMapping, batch.results);

    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                overflow="scroll"
                flexGrow={1}
            >
                <BatchPictureAndMasks
                    pictureURL={pictureURL}
                />

                <Box
                    my={2}
                    px={3}
                >
                    <Grid container alignItems="center">
                        <Grid item md={12}>
                            <Typography
                                fontSize="1rem"
                                fontWeight={500}>
                                {t("datetime_full", { ns: Namespace.DATES, datetime: batch.timestamp })}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="body2">
                        {placeAddress}
                    </Typography>

                    {batch.binsRFIDs && (
                        <Box mb={1}>
                            <Typography variant='body2'>
                                {t("rfid_read", {
                                    ns: Namespace.COLLECTIONS,
                                    count: batch.binsRFIDs.length,
                                    rfid: batch.binsRFIDs.join(", ")
                                })}
                            </Typography>
                        </Box>
                    )}

                    <Typography variant="caption">
                        <Trans
                            i18nKey="coordinates"
                            ns={Namespace.COMMONS}
                            values={{
                                latitude: latitude,
                                longitude: longitude,
                            }}
                            components={{
                                bold: <strong />
                            }}
                        />
                    </Typography>

                    {placeAddress && (
                        <Box>
                            <Button
                                color="info"
                                target="_blank"
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(placeAddress)}`}
                                endIcon={<MapIcon />}
                                sx={{
                                    ml: -1,
                                    color: theme => theme.palette.info.dark,
                                }}
                            >
                                {t("view_in_google_maps", { ns: Namespace.ACTIONS })}
                            </Button>
                        </Box>
                    )}

                    {batch.binsCounts && (
                        <BinsCountsLabel binsCounts={batch.binsCounts} />
                    )}
                </Box>

                <Divider />

                <Box
                    my={2}
                    px={3}
                >
                    <Typography variant="h6" mb={2}>
                        {t("sorting_error", { ns: Namespace.GLOSSARY, count: 2 })}
                    </Typography>
                    <Alert variant="outlined" severity={getAlertColor(BatchesController.getDangerLevel(getErrorsCount(errorsClasses, batch.results)))}>
                        {Object.keys(errors).length > 0 ? (
                            Object.entries(errors).map(([trashType, count]) => (
                                <Typography
                                    key={trashType}
                                    variant="body2"
                                    display="flex"
                                    alignItems="center">
                                    <Box
                                        component="span"
                                        display="inline-block"
                                        width={(theme) => theme.spacing(2)}
                                        height={(theme) => theme.spacing(2)}
                                        mr={1}
                                        sx={{
                                            bgcolor: WASTES_COLORS[trashType as TrashType],
                                        }}
                                    />
                                    {`${t(trashType, { ns: Namespace.WASTES })}: ${count}`}
                                </Typography>
                            ))
                        ) : ( // no sorting error
                            <Typography variant="body2">
                                {t("no_sorting_error", { ns: Namespace.GLOSSARY })}
                            </Typography>
                        )}
                    </Alert>
                </Box>
            </Box>

            <BatchDetailsActions />
        </Box>
    );
}

export default BatchDetailsBody;