import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MergingWasteMapping, TrashType } from "constants/trash";
import { applySortingRules, getDisplayedErrorsClasses, getMergingWasteMapping } from "helpers/trash";
import { CollectionType } from "models/Collection";
import Partner from "models/Partner";

type SortingRulesContext = {
    errorsClasses: TrashType[];
    mergingMapping: MergingWasteMapping;
    displayedErrors: TrashType[];
    collectionType: CollectionType;
}

const DEFAULT_COLLECTION_TYPE = CollectionType.SORTABLE_WASTE;
const DEFAULT_ERRORS_CLASSES = applySortingRules(undefined, DEFAULT_COLLECTION_TYPE);
const DEFAULT_MERGING_MAPPING = getMergingWasteMapping();

const initialState: SortingRulesContext = {
    errorsClasses: DEFAULT_ERRORS_CLASSES,
    mergingMapping: DEFAULT_MERGING_MAPPING,
    displayedErrors: getDisplayedErrorsClasses(DEFAULT_ERRORS_CLASSES, DEFAULT_MERGING_MAPPING),
    collectionType: DEFAULT_COLLECTION_TYPE,
}

export const sortingRulesSlice = createSlice({
    name: 'selected_partner',
    initialState: initialState,
    reducers: {
        setRules: (state, { payload: sortingRules }: PayloadAction<Partner["sortingRules"] | undefined>) => {
            const errorsClasses = applySortingRules(sortingRules, state.collectionType);
            const mergingMapping = getMergingWasteMapping();
            state.errorsClasses = errorsClasses;
            state.mergingMapping = mergingMapping;
            state.displayedErrors = getDisplayedErrorsClasses(errorsClasses, mergingMapping);
        },
    }
});


export const SortingRulesActions = sortingRulesSlice.actions;

const SortingRulesReducer = sortingRulesSlice.reducer;

export default SortingRulesReducer;