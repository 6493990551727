import Partner from "models/Partner";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext } from "store/store";

type SelectedPartnerContext = LoadableContext & {
    data: Partner | undefined;
};

const initialState: SelectedPartnerContext = {
    loading: false,
    error: null,
    data: undefined,
};


export const selectedPartnerSlice = createSlice({
    name: 'selected_partner',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setSelected: (state, { payload: partner }: PayloadAction<Partner | undefined>) => {
            state.loading = false;
            state.data = partner;
        },
        update: (state, { payload: data }: PayloadAction<Partial<Partner>>) => {
            state.loading = false;
            if (state.data) {
                state.data = {
                    ...state.data,
                    ...data,
                };
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedPartnerActions = selectedPartnerSlice.actions;

const SelectedPartnerReducer = selectedPartnerSlice.reducer;

export default SelectedPartnerReducer;