import { ListItemButton, ListItemText, ListItemIcon, Tooltip } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import { useAppSelector, useSortingRules } from "store/hooks";
import { selectBatchById } from "store/reducers/batches/list";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TrashType } from "constants/trash";
import TrashColorSquare from "components/_include/TrashColorSquare";
import { getDisplayedErrors, getErrorsCount } from "helpers/trash";
import VisitedIcon from "components/_include/Icons/VisitedIcon";
import { getDangerColor } from "helpers/draw";

type ItemButtonProps = {
    batchID: EntityId;
    onClick: (batchID: string) => void;
    onMouseEnter: (batchID: string) => void;
    onMouseLeave: (batchID: string) => void;
};

export default function SelectBatchItemButton({ batchID, onClick, onMouseEnter, onMouseLeave, }: ItemButtonProps) {
    const { t } = useTranslation([Namespace.DATES, Namespace.GLOSSARY, Namespace.STATS]);

    const { errorsClasses, mergingMapping } = useSortingRules();

    const batch = useAppSelector(state => selectBatchById(state, batchID));

    const hovered = useAppSelector(state => state.batches.list.hoveredBatchID === batchID);

    if (!batch) return (<></>);

    let visited = localStorage.getItem(`visited-batch-${batch.ID}`);

    const errorsCount = getErrorsCount(errorsClasses, batch.results);

    return (
        <ListItemButton
            autoFocus={hovered}
            sx={{
                borderBottom: `1px solid #EEEEEE`,
                ...(hovered && { backgroundColor: "#eeeeee" }),
                // opacity: visited ? .6 : 1,
                // bgcolor: visited ? "#DDDDDD" : "#FFFFFF",
            }}
            onClick={() => onClick(batchID.toString())}
            onMouseEnter={() => onMouseEnter(batchID.toString())}
            onMouseLeave={() => onMouseLeave(batchID.toString())}
        >
            <ListItemText
                primary={t("datetime_full", { ns: Namespace.DATES, datetime: batch.timestamp }).toString()}
                secondary={
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span style={{ marginRight: "1rem" }}>
                            {t("errors_count", { ns: Namespace.GLOSSARY, count: errorsCount })}
                        </span>
                        {Object.entries(getDisplayedErrors(errorsClasses, mergingMapping, batch.results)).map(([trashType, count]) => {
                            const squares = [];
                            for (let i = 0; i < count; i++) {
                                squares.push(
                                    <TrashColorSquare
                                        key={`${trashType}-${i}`}
                                        trashType={trashType as TrashType}
                                    />
                                );
                            }
                            return squares;
                        })}
                    </span>
                }
                secondaryTypographyProps={{
                    color: getDangerColor(errorsCount),
                }}
            />
            <ListItemIcon>
                {visited ? (
                    <Tooltip
                        title={t("visited", { ns: Namespace.COMMONS })}
                    >
                        <VisitedIcon color="success" />
                    </Tooltip>
                ) : (
                    <ChevronRightIcon />
                )}
            </ListItemIcon>
        </ListItemButton>
    );
}