import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { UNIX_FORMAT } from 'constants/dates';
import MissionsMethods from 'controllers/mission';
import { Namespace } from 'locales/translations';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';

type MissionDetailHeaderProps = {
};

const MissionDetailHeader: React.FC<MissionDetailHeaderProps> = ({ }) => {
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.MISSIONS]);

    const createdAt = useAppSelector(state => state.mission.selected.selectedMission?.createdAt);
    const deadline = useAppSelector(state => state.mission.selected.selectedMission?.deadline);

    const saving = useAppSelector(state => state.mission.selected.loading);

    const mission = useAppSelector(state => state.mission.selected.selectedMission);
    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();
    const navigate = useNavigate();

    const handleSave = async () => {
        if (!mission || !partnerID) return;

        const result = await dispatch(MissionsMethods.update(partnerID, mission.ID, mission));

        if (result) {
            navigate(`/missions?partnerID=${partnerID}`);
        }
    };

    return (
        <Box display="flex" justifyContent="space-between" pr={5} alignItems="center">
            <Box>
                <Typography variant="body2" pb={1} color="text.secondary">
                    {t('created at', {ns: Namespace.MISSIONS})}: {moment(createdAt).format('LL')}
                </Typography>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {t('deadline', {ns: Namespace.MISSIONS})}: {moment(deadline, UNIX_FORMAT).format('LL')}
                </Typography>
            </Box>

            <LoadingButton
                variant="contained" 
                color="primary" 
                onClick={handleSave} 
                loading={saving}
            >
                <span>{t("save", { ns: Namespace.ACTIONS })}</span>
            </LoadingButton>
        </Box>
    );
};

export default MissionDetailHeader;
