import MostFrequentErrorsBarChart, { ERROR_CATEGORY, BAR_COLORS, TEXT_COLORS } from "components/_include/Charts/MostFrequentErrors/MostFrequentErrorsBarChart";
import { mergeTrashCounts } from "helpers/trash";
import { max } from "lodash";
import { useMemo } from "react";
import { useAppSelector, useSortingRules } from "store/hooks";
import { ErrorPoint } from "../../../_include/Charts/AddressesWithMostErrors/AddressesWithMostErrors";
import { TrashType } from "constants/trash";

export default function MostFrequentErrorsChart() {
    const collectionResults = useAppSelector(state => state.collections.selected.data?.results);

    const { mergingMapping, displayedErrors } = useSortingRules();

    const data: ErrorPoint[] = useMemo(() => {
        let errorsTypes: ErrorPoint[] = [];
        if (!collectionResults) return errorsTypes; // results not loaded yet

        const mergedResults = mergeTrashCounts(mergingMapping, collectionResults);
        const maxErrorCount = max(
            Object.entries(mergedResults)
                .filter(([trashType, _]) => displayedErrors.includes(trashType as TrashType))
                .map(([_, count]) => count)
        )!;

        for (const errorType of displayedErrors) {
            const count = mergedResults[errorType] ?? 0;

            const portion = count / maxErrorCount;
            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.5) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.1) category = ERROR_CATEGORY.MEDIUM;
            else category = ERROR_CATEGORY.GOOD;

            errorsTypes.push({
                trashType: errorType,
                count,
                color: BAR_COLORS[category],
                textColor: TEXT_COLORS[category],
            });
        }

        return errorsTypes.sort((p1, p2) => p1.count - p2.count); // sort by items count desc
    }, [collectionResults]);

    return (
        <MostFrequentErrorsBarChart
            data={data}
            interactive
            height={(theme) => theme.spacing(36)} px={3}
            icons={false}
        />
    )
}