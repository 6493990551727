import { UNIX_FORMAT } from "constants/dates";
import { DbCollection } from "constants/db";
import urls from "constants/urls";
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, Timestamp, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getCollectionRef, getDocumentReference } from "helpers/db";
import i18next from "i18next";
import { Namespace } from "locales/translations";
import { MissionDbData, MissionsData } from "models/Missions";
import moment from "moment";
import { MissionsActions } from "store/reducers/missions/list";
import { SelectedMissionActions } from "store/reducers/missions/selected";
import { showError, showTranslatedMessage } from "store/reducers/snacks";
import { AppDispatch } from "store/store";
import { fetchAPI, handleAPIError } from "./actions";

/**
 * Serialize a Mission's data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): MissionsData;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): MissionsData | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as MissionDbData;

    let deadline: string | undefined = undefined;
    if (data.deadline) {
        if (data.deadline instanceof Timestamp) deadline = moment(data.deadline.toDate()).format(UNIX_FORMAT);
        else deadline = moment(data.deadline).format(UNIX_FORMAT);
    }

    const missionData: MissionsData = {
        ...data,
        ID: dbDoc.id,
        createdAt: data.createdAt ? moment(data.createdAt.toDate()).format(UNIX_FORMAT) : undefined,
        deadline,
    };

    return missionData;
}

const create = (missionData: MissionDbData, partnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoading());
    const missionsPath = [DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS];
    try {
        const missionsRef = getCollectionRef(missionsPath);
        const missionDoc = doc(missionsRef);
        const mission = {
            ...missionData,
            ID: missionDoc.id,
        };
        await setDoc(missionDoc, mission);
        dispatch(MissionsMethods.getMissions(partnerID));
        dispatch(SelectedMissionActions.stopLoading());
        return mission;
    }
    catch (e) {
        const { message } = e as Error;
        console.error("Couldn't create mission", message);
        dispatch(SelectedMissionActions.setError(message));
        dispatch(showError(i18next.t("missions.create.error", { ns: Namespace.SNACKS, name: missionData.name })));
        return null;
    }
}

/**
  * Retrieves a list of missions for a specific partner.
  * @param {AppDispatch} dispatch - The dispatch function from the Redux store.
  * @param {string} partnerID - The ID of the partner.
  * @returns {Promise<MissionsData[]>} The list of missions.
 */
const getMissions = (partnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(MissionsActions.startLoadingList());

    try {
        const missionsPath = [DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS];
        const missionsRef = getCollectionRef(missionsPath); // Get the collection reference
        const missionDocs = await getDocs(missionsRef); // Use getDocs to fetch the collection data

        // Filter out null values
        const missions: MissionsData[] = missionDocs.docs
            .map(fromDbDoc) // Convert documents to mission data
            .filter((mission): mission is MissionsData => mission !== null); // Keep only non-null missions

        dispatch(MissionsActions.setList(missions));
        return missions;
    } catch (e) {
        const error = e as Error;
        console.error("Failed loading missions", error);
        dispatch(MissionsActions.setError(error.message));
        dispatch(showError(error.message));
        return [];
    }
};

/**
  * Retrieves a single missions for a specific partner.
  * @param dispatch - The dispatch function from the Redux store.
  * @param partnerID - The ID of the partner.
  * @param missionID - The ID of the mission to retrieve.
  * @returns The list of missions.
 */
const retrieve = (partnerID: string, missionID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoading());

    try {
        const missionRef = getDocumentReference(missionID, DbCollection.MISSIONS, `${DbCollection.PARTNERS}/${partnerID}`);
        const missionDoc = await getDoc(missionRef);

        // Filter out null values
        const mission = fromDbDoc(missionDoc);

        dispatch(SelectedMissionActions.setSelected(mission));
        return mission;
    } catch (e) {
        dispatch(handleAPIError(e, "retrieving mission", MissionsActions.setError));
        return null;
    }
};

const removeUndefinedFields = (obj: any) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {} as any);
};

/**
  * Updates a single mission for a specific partner.
  * @param {AppDispatch} dispatch - The dispatch function from the Redux store.
  * @param {string} partnerID - The ID of the partner.
  * @param {string} missionID - The ID of the mission to update.
  * @param {Partial<MissionsData>} updatedData - The updated data for the mission.
  * @returns {Promise<MissionsData | null>} The updated mission data or null if an error occurs.
 */
const update = (partnerID: string, missionID: string, updatedData: Partial<MissionsData>) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoading());

    try {
        const missionRef = getDocumentReference(missionID, DbCollection.MISSIONS, `${DbCollection.PARTNERS}/${partnerID}`);

        // Remove undefined values from the updatedData
        const sanitizedData = removeUndefinedFields(updatedData);
        await setDoc(missionRef, sanitizedData, { merge: true }); // Merge the updated data with the existing data

        await fetchAPI(urls.HTTP_FUNCTIONS.staticMapImage, { //Calls an http function to genrate a static map image for mission cards
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                partnerID: partnerID,
                missionID: missionID,
                size: "300x300"
            }),
        });

        const missionDoc = await getDoc(missionRef);
        const mission = fromDbDoc(missionDoc)!;

        dispatch(MissionsActions.updateItem({ ID: missionID, data: mission }));
        dispatch(SelectedMissionActions.stopLoading());
        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "missions.update.success",
            context: { name: mission.name },
        }));

        return mission;
    } catch (e) {
        dispatch(handleAPIError(e, "updating mission", MissionsActions.setError));
        return null;
    }
};


const select = (mission: MissionsData) => (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.setSelected(mission));
};

const MissionsMethods = {
    create,
    select,
    retrieve,
    getMissions,
    update,
};

export default MissionsMethods;
