import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Paper, Theme } from '@mui/material';
import { HEADER_HEIGHT_SPACING } from 'components/Missions/MissionDetail/MissionDetailPage';
import { DRAWER_WIDTH } from 'components/Navigation/Drawer';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAllSortingAddressesKeys } from 'store/reducers/batches/sorting_map';
import PlacesListDrawerHeader from './PlacesListDrawerHeader';
import PlacesListResults from './PlacesListResults';

type PlacesListDrawerProps = {
    showForMissions: boolean;
}

function PlacesListDrawer(props: PlacesListDrawerProps) {
    const { showForMissions } = props;
    
    const [open, setOpen] = useState(false);

    const placesCount = useAppSelector(state => selectAllSortingAddressesKeys(state).length);
    const placesLoading = useAppSelector(state => state.batches.sortingMap.loading);

    /**
     * Open drawer when number of places change
     */
    useEffect(() => {
        if (placesCount > 0) setOpen(true);
    }, [placesCount]);

    useEffect(() => {
        if (placesLoading) {
            setOpen(false);
        }
    }, [placesLoading]);

    const getMarginLeft = (theme: Theme) => {
        if (open) { // drawer open
            return theme.spacing(showForMissions ? -5 : 0);
        }
        // drawer closed
        return `calc(-${DRAWER_WIDTH}px - ${theme.spacing(showForMissions ?  5.5 : 2)})`;
    }

    return (
        <Box 
            position="relative"
            sx={{
                width: (theme) => `calc(${DRAWER_WIDTH}px + ${theme.spacing(10)})`,
                ml: getMarginLeft,
                transition: "margin-left 300ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                pointerEvents: "all",
            }}>
            <Button
                onClick={() => setOpen(!open)}
                variant="contained"
                color="white"
                sx={{
                    px: .5,
                    height: (theme) => theme.spacing(7),
                    minWidth: 0,
                    position: "absolute",
                    right: (theme) => open ? theme.spacing(-2.5) : theme.spacing(2.0),
                    top: (theme) => `calc(50% - ${theme.spacing(3)})`,
                    ...(placesLoading || !placesCount ? { display: "none" } : {}),
                }}
            >
                {open ? (
                    <ChevronLeftIcon />
                ) : (
                    <ChevronRightIcon />
                )}
            </Button>
            <Paper
                sx={{
                    minWidth: DRAWER_WIDTH + 10,
                    height: (theme) => `calc(100vh - ${theme.spacing((showForMissions ? HEADER_HEIGHT_SPACING : 0) + 2)})`, // spacing(1) margin at bottom
                    mr: 1,
                    position: "relative",
                    overflow: "hidden",
                    visibility: open ? 'visible' : 'hidden',
                    opacity: open ? 1 : 0,
                    transition: "opacity .5s",
                    pointerEvents: open ? 'auto' : 'none', 
                }}
                elevation={4}
            >
                <PlacesListDrawerHeader showForMissions={showForMissions} />

                <PlacesListResults showForMissions={showForMissions} />
            </Paper>
        </Box>
    );
}

export default PlacesListDrawer;