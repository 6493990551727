import { Box, Card, CardContent } from '@mui/material';
import PartnerName from './PartnerName/PartnerName';
import PartnerStatus from './PartnerStatus/PartnerStatus';
import PartnerLogoButton from './PartnerLogo/PartnerLogoButton';
import { useState } from 'react';
import Partner, { PartnerStatus as PartnerStatusEnum } from 'models/Partner';
import NewPartnerSaveButton from '../NewPartner/NewPartnerSaveButton';
import { useAppDispatch } from 'store/hooks';
import PartnersController from 'controllers/partners';
import { showSuccess } from 'store/reducers/snacks';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';

type PartnerCardProps = {
    partnerID: string;
    onPartnerCreated?: undefined;
} | {
    partnerID: null;
    onPartnerCreated: (partner: Partner) => void;
}

function PartnerCard({ partnerID, onPartnerCreated }: PartnerCardProps) {
    const [logoURL, setLogoURL] = useState<string>();
    const [name, setName] = useState<string>();
    const [status, setStatus] = useState<PartnerStatusEnum>();

    const dispatch = useAppDispatch();

    const validForm = logoURL && name && status;

    const { t } = useTranslation([Namespace.SNACKS]);

    const handleSave = async () => {
        if (!validForm) return;

        const partner = await dispatch(PartnersController.create({ logoURL, name, status }));

        if (partner) { // partner added successfully
            dispatch(showSuccess(t("partners.create.success", { ns: Namespace.SNACKS, name: partner.name })));

            if (onPartnerCreated) onPartnerCreated(partner);
        }
    }

    return (
        <Card>
            <CardContent
                sx={{
                    p: 4
                }}
            >
                <Box>
                    <PartnerLogoButton partnerID={partnerID} onChange={setLogoURL} />
                </Box>
                <Box mt={3}>
                    <PartnerName partnerID={partnerID} onChange={setName} />
                    <PartnerStatus partnerID={partnerID} onChange={setStatus} />
                </Box>

                {partnerID === null && (
                    <Box mt={2}>
                        <NewPartnerSaveButton
                            disabled={!validForm}
                            onClick={handleSave}
                            />
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default PartnerCard;